import React from 'react';
import styled from 'styled-components';
import Footernav from './Footernav';

const FooterStyles = styled.footer`
  background-color: #050506;
  padding: 20px 0 15px;
  div.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 650px) {
      flex-direction: column-reverse;
      padding: 18px 0 13px;
    }
    nav {
      margin-bottom: 10px;
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        li {
          position: relative;
          &:first-of-type {
            padding-right: 8px;
            margin-right: 8px;
            &:after {
              position: absolute;
              content: '';
              border-right: 1px solid var(--white);
              width: 1px;
              height: 16px;
              top: 7px;
              right: 0;
            }
          }
          a {
            font-family: Barlow;
            font-size: 1.5rem;
            color: var(--white);
            border-bottom: 1px solid transparent;
            text-decoration: none;
            transition: 0.34s all ease-in-out;
            &:hover {
              border-bottom: 1px solid var(--white);
            }
          }
          &:before,
          &:marker {
            content: none;
          }
        }
      }
    }
  }
  small {
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    color: var(--white);
    span {
      color: var(--white);
    }
    @media screen and (max-width: 650px) {
      display: block;
      span {
        display: block;
        margin-top: 2px;
        text-align: center;
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <footer>
        <div className="wrap">
          <div className="footer">
            <small>
              &copy; {new Date().getFullYear()} Stephen Gerber Music.{' '}
              <span>All rights reserved.</span>
            </small>
            <Footernav />
          </div>
        </div>
      </footer>
    </FooterStyles>
  );
}
